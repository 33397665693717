import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import IndustryPageAnnouncements from "../components/IndustryPageAnnouncements"
import Testimonial from "../components/Testimonial"
import { GatsbyImage } from "gatsby-plugin-image"

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Healthcare = ({ data, location }) => {
  const content = data.contentfulIndustryPage
  const announcements = data.allContentfulAnnouncement.nodes
  const testimonials = data.allContentfulTestimonial.nodes

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (content, children) => <p>{children}</p>,
    },
  }

  return (
    <Layout location={location}>
      <Seo title="Healthcare" />
      <div className="section-wrapper page-main">
        <div className="content-column">
          <h1 className="page-main-title">Healthcare</h1>

          <GatsbyImage
            image={content.image.gatsbyImageData}
            alt="Doctors performing surgery"
            className="industry-image"
          />

          <div className="text-section">
            <h2 id="capabilities">About</h2>
            <div>{renderRichText(content.capabilities, options)}</div>
          </div>
          {/* <div className="text-section">
            <h2 id="accomplishments">Accomplishments</h2>
            <div>{renderRichText(content.accomplishments, options)}</div>
          </div> */}
          {testimonials.length > 0 && (
            <div className="text-section">
              <h2 id="testimonials">Testimonials</h2>
              {testimonials.map(testimonial => {
                return (
                  <Testimonial
                    name={testimonial.name}
                    // title={testimonial.titleAndCompany}
                    quote={testimonial.quote.quote}
                    // image={testimonial.headshot.fixed.src}
                  />
                )
              })}
            </div>
          )}
          {announcements.length > 0 && (
            <div className="text-section">
              <h2 id="announcements">Relevant Announcements</h2>
              <IndustryPageAnnouncements announcements={announcements} />
            </div>
          )}
        </div>
        <div className="index-column">
          <AnchorLink to="/healthcare#capabilities" className="index-item">
            About
          </AnchorLink>
          {/* <AnchorLink to="/healthcare#accomplishments" className="index-item">Accomplishments</AnchorLink> */}
          {testimonials.length > 0 && (
            <AnchorLink to="/healthcare#testimonials" className="index-item">
              Testimonials
            </AnchorLink>
          )}
          {announcements.length > 0 && (
            <AnchorLink to="/healthcare#announcements" className="index-item">
              Relevant Announcements
            </AnchorLink>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Healthcare

export const pageQuery = graphql`
  query HealthcareContent {
    site {
      siteMetadata {
        title
      }
    }
    contentfulIndustryPage(slug: { eq: "healthcare" }) {
      slug
      title
      image {
        gatsbyImageData
      }
      capabilities {
        raw
      }
    }
    allContentfulAnnouncement(
      filter: { taggedIndustry: { eq: "Healthcare" } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        slug
        title
        date(formatString: "MMMM Do, YYYY")
      }
    }
    allContentfulTestimonial(filter: { taggedIndustry: { eq: "Healthcare" } }) {
      nodes {
        name
        quote {
          quote
        }
      }
    }
  }
`
